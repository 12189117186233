@media (max-width: 1000px) {
  .login-component {
    flex-direction: column;
    flex: 1;
  }

  .login-component .logo-panel {
    /* margin-top: 3rem; */
    height: auto;
  }

  .login-component .form-panel {
    /* flex: 1; */
    height: auto;
  }

  .login-logo {
    visibility: hidden;
  }

  .login-logo-icon-small-screen {
    display: block;
  }

  .register-form-second-col {
    margin-top: 1rem;
  }

  .transaction-history {
    font-size: 1rem;
  }

  .export-container {
    justify-content: flex-start;
    margin-top: 1rem;
  }

  .point-input-control {
    width: 100% !important;
  }

  .calendar-input-control {
    width: 100% !important;
  }

  .customer-search-input-control {
    width: 100% !important;
  }

  .new-customer-header-container {
    flex-direction: column;
  }

  .new-customer-title {
    align-self: flex-start;
  }

  .new-customer-breadcrumb {
    align-self: flex-start;
  }

  .customer-profile-info-container {
    margin-left: 0px;
  }

  .customer-profile-layout {
    flex-direction: column;
  }

  .customer-profile-avatar {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }

  .customer-profile-form-second-col {
    margin-top: 1rem;
  }

  .pagination-container {
    flex-direction: column;
  }

  .transaction-search-container {
    margin-top: 0rem;
  }

  .pagination-select-right-container {
    margin-left: 0rem;
  }

  .point-history-title {
    width: 30%;
    /* margin-top: 1rem; */
    margin-right: 1.5rem;
  }

  .cus-detail-info-external-contaianer {
    border-bottom: 2px solid #fbfbfb;
    border-left: 0px;
    margin-top: 1rem;
    width: 100%;
  }

  .cus-name-outer-container {
    border-bottom: 2px solid #fbfbfb;
    padding-bottom: 1rem;
    width: 100%;
  }

  .cus-name-outer-container .avatar {
    height: 3rem;
    width: 3rem;
  }

  .cus-name-container {
    flex: 1;
    margin-left: 1rem;
  }

  .c-name {
    text-align: left;
  }

  .c-point {
    text-align: left;
  }

  .c-cash {
    text-align: left;
  }

  .calendar-cus-tran-input-control {
    width: 100% !important;
  }

  .customer-info-external-layout {
    flex-direction: column;
    align-items: flex-start;
  }

  .transaction-component-info-container {
    flex: 0.8;
    margin-right: 1rem;
  }

  .trans-pts-add-container {
    align-self: baseline;
    flex: 0.2;
  }

  .trans-pts-minus-container {
    align-self: baseline;
    flex: 0.2;
  }

  .transaction-search-field-title {
    margin-top: 1rem;
  }

  .indicator-container {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 768px) {
  .not-found-container {
    height: calc(100vh - 6rem);
  }
}

@media (max-width: 768px) {
  .nav-separator {
    border-bottom: none;
    background-color: transparent !important;
  }
}

@media (max-width: 650px) and (min-width: 300px) {
  .rewards-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
