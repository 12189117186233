@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;700;800&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/************************************************************************************
commmon start
************************************************************************************/
.full-width-colored-button {
  background-color: var(--button-background-color) !important;
  color: var(--positive-button-text-color) !important;
  width: 100%;
}

.full-width-bordered-button {
  background-color: #fff !important;
  color: var(--bordered-button-text-color) !important;
  width: 100%;
  border: 1px solid var(--bordered-button-border-color) !important;
}

.colored-button {
  background-color: var(--button-background-color) !important;
  color: var(--positive-button-text-color) !important;
}

.bordered-button {
  background-color: #fff !important;
  color: var(--bordered-button-text-color) !important;
  border: 1px solid var(--bordered-button-border-color) !important;
}

.space-between-field {
  margin-top: 2rem;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
}

.preloader {
  vertical-align: middle;
  border-radius: 0;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 99998;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 300px 200px rgba(255, 255, 255, 0.4);
  margin: auto;
  right: 0;
  bottom: 0;
}
.css-loader {
  border: 5px solid transparent;
  border-radius: 50%;
  border-top: 5px solid var(--loading-color);
  border-bottom: 5px solid var(--loading-color);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  position: absolute;
  top: -30px;
  left: -30px;
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.space-between-field-one {
  margin-top: 1rem;
}

.general-outermost-container {
  background: white;
  margin: 1rem;
  border-radius: 5px;
  display: flex;
  padding: 1rem;
  height: 100vh;
  flex-direction: column;
  flex: 1;
  /* overflow-y: auto; */
}

.outermost-container {
  background: white;
  margin: 1rem;
  border-radius: 5px;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  flex: 1;
  /* overflow-y: auto; */
}

.table-responsive {
  margin-bottom: auto;
}

.table-header-text {
  text-transform: none !important;
  font-size: 0.8125rem !important;
  color: #24293a;
}

.table-first-index-col {
  width: 5% !important;
}

.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
}

.pagination-select-left-container {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
}

.pagination-select-right-container {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: var(--button-background-color) !important;
  border-color: var(--button-background-color) !important;
  border-radius: 0.25rem !important ;
}

.dropdown-page-select {
  background: #c0bfbf 0% 0% no-repeat padding-box !important;
  border-radius: 0.25rem !important;
  height: 1.875rem !important;
}

.export-icon {
  cursor: pointer;
}

.rotate-icon {
  transform: rotateZ(180deg);
}

.sort-icon {
  height: 6px;
  width: 10px;
}
/************************************************************************************
commmon end
************************************************************************************/

/************************************************************************************
login start
************************************************************************************/
.login-component {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
}

.login-component .logo-panel {
  flex: 1;
  display: flex;
  height: 100vh;
}

.login-component .form-panel {
  flex: 1;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  color: #24293a;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100vh;
  overflow-y: auto;
}

.login-logo {
  background-image: url(./assets/img/partners_icon.png) !important;
  justify-content: center;
  text-align: center;
  display: flex;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.login-logo-icon {
  display: block;
  margin: auto;
}

.login-logo-icon-small-screen {
  margin: auto;
}

.login-form-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
}

.login-title {
  font-weight: 800;
  font-size: 1.625rem;
}

.login-form-title {
  font-weight: 700;
  font-size: 1rem;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
}

.login-input-control .form-control {
  border: 1px solid #e4e4e4;
  padding-left: 1rem !important;
}

.login-input-control .form-control::placeholder {
  color: #e6e7e9;
  opacity: 1;
  font-weight: 700;
  font-style: italic;
}

.login-input-control .form-control:focus {
  background: white;
  color: #393939;
}

.login-input-control-append .form-control::placeholder {
  color: #e6e7e9;
  opacity: 1;
  font-weight: 700;
  font-style: italic;
}

.login-input-control-append .form-control:focus {
  background: white;
  color: #393939;
}

.login-form-append-text {
  border-left: 0px !important;
  border-color: #cad1d7;
}

.login-form-append-text-error {
  border-left: 0px !important;
  border-color: red !important;
}

.forgot-password-text {
  font-weight: 800;
  font-style: italic;
  font-size: 0.7rem;
  text-align: end;
  margin-right: 1rem;
  margin-top: 0.5rem;
  color: #424755;
  cursor: pointer;
}

.login-button {
  /* @include variable(background-color, --button-background-color, green) !important */
  background-color: var(--button-background-color) !important;
  color: var(--positive-button-text-color) !important;
  width: 100%;
}
/************************************************************************************
login end
************************************************************************************/

/************************************************************************************
sidebar start
************************************************************************************/
.customer-icon {
  background-image: url(./assets/img/customer_icon_unactive.png) !important;
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.nav-link:hover .customer-icon {
  background-image: url(./assets/img/customer_icon_active.png) !important;
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.nav-link.active .customer-icon {
  background-image: url(./assets/img/customer_icon_active.png) !important;
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.transaction-icon {
  background-image: url(./assets/img/transaction_icon_unactive.png) !important;
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.nav-link:hover .transaction-icon {
  background-image: url(./assets/img/transaction_icon_active.png) !important;
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.nav-link.active .transaction-icon {
  background-image: url(./assets/img/transaction_icon_active.png) !important;
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.reward-icon {
  background-image: url(./assets/img/reward_icon_unactive.png) !important;
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.nav-link:hover .reward-icon {
  background-image: url(./assets/img/reward_icon_active.png) !important;
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.nav-link.active .reward-icon {
  background-image: url(./assets/img/reward_icon_active.png) !important;
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.sidebar-background {
  background: var(--sidebar-background) !important;
}

.crm .navbar-nav .nav-link {
  color: var(--sidebar-inactive-text);
}

.crm .navbar-nav .nav-link:hover,
.crm .navbar-nav .nav-link:focus {
  color: var(--sidebar-hover-text);
}

.crm .navbar-nav .nav-link > i:hover {
  color: var(--sidebar-hover-text);
}

.crm .navbar-nav .show > .nav-link,
.crm .navbar-nav .active > .nav-link,
.crm .navbar-nav .nav-link.show,
.crm .navbar-nav .nav-link.active {
  color: var(--sidebar-active-text);
  font-weight: bold;
}

.crm .navbar-nav .nav-link {
  border: none !important;
}

.crm .navbar-nav .nav-link.active:before {
  border-color: var(--sidebar-active-text) !important;
}

.crm .navbar-collapse .navbar-nav .nav-link {
  color: var(--sidebar-inactive-text) !important;
}

.crm .navbar-collapse .navbar-nav .nav-link:hover,
.crm .navbar-collapse .navbar-nav .nav-link:focus {
  color: var(--sidebar-hover-text) !important;
}

.crm .navbar-collapse .navbar-nav .nav-link > i:hover {
  color: var(--sidebar-hover-text) !important;
}

.crm .navbar-collapse .navbar-nav .show > .nav-link,
.crm .navbar-collapse .navbar-nav .active > .nav-link,
.crm .navbar-collapse .navbar-nav .nav-link.show,
.crm .navbar-collapse .navbar-nav .nav-link.active {
  color: var(--sidebar-active-text) !important;
  font-weight: bold;
}

.sidebar-img {
  height: auto;
  width: 80%;
}
/************************************************************************************
sidebar end
************************************************************************************/

/************************************************************************************
header nav start
************************************************************************************/
.nav-separator {
  border-bottom: 1px solid #fbfbfb;
  background-color: var(--header-nav-background) !important;
}

.header-nav-text {
  color: var(--header-nav-text);
  font-size: 0.865rem;
  font-weight: 600;
}

.header-nav-name-text {
  color: var(--header-nave-name-text);
  font-size: 0.865rem;
  font-weight: bold;
}

.header-nav-avatar {
  margin-left: 0.5rem;
}

.header-nav-profile-icon {
  margin-right: 1rem;
}

.header-nav-profile-container {
  background-color: var(--header-nav-profile-container);
  padding: 1rem;
  display: flex;
  flex-direction: row;
}

.header-nav-profile-container .nav-avatar {
  margin-top: auto;
  margin-bottom: auto;
}

.header-nav-profile-text-container {
  padding-left: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.header-nav-profile-text-container .header-nav-name-text {
  font-size: 1.125rem;
  opacity: 0.99;
  color: #fff;
}

.header-nav-profile-text-container .header-nav-role-text {
  font-size: 0.875rem;
  opacity: 0.5;
  color: #fff;
}

.header-nav-dropdown-text {
  font-weight: bold;
  font-size: 0.9375rem;
  color: #000;
}

.header-nav-signout-text {
  font-weight: 800;
  color: var(--header-nav-signout-text);
  background-color: var(--header-nav-signout-button);
  cursor: pointer;
  border-radius: 0.3125rem;
  padding: 1rem;
  display: inline-block;
}

.header-nav-dropdown-menu {
  padding-top: 0rem !important;
}

.header-nav-dropdown-header {
  padding: 0rem !important;
}

.dropwon-align-left {
  text-align: end !important;
}
/************************************************************************************
header nav end
************************************************************************************/

/************************************************************************************
transaction start
************************************************************************************/
.transaction-search-container {
  background-color: #fff;
  margin-top: 1rem;
  /* overflow-x: hidden; */
}
.row-container {
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 15px;
  padding-right: 15px;
}

.transaction-search-layout {
  display: flex;
  flex-direction: row;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  flex: 1;
}

.transaction-history {
  border-right: 1px solid #fbfbfb;
  color: #000;
  font-weight: bold;
  padding-right: 1rem;
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.3125rem;
}

.export-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.calendar-input-control {
  width: 70% !important;
}

.calendar-input-control .form-control {
  border: 1px solid #e4e4e4 !important;
  padding-left: 1rem !important;
  border-left: 0px !important;
  font-weight: bold !important;
  color: #24293a !important;
}

.calendar-input-control .form-control:read-only {
  background: white !important;
}

.calendar-input-control .form-control::placeholder {
  color: #e6e7e9;
  opacity: 1;
  font-weight: 700;
  font-style: italic;
}

.calendar-input-control .form-control:focus {
  background: white;
  color: #393939;
}

.calendar-form-prepend-text {
  font-weight: 700 !important;
  color: #24293a !important;
  font-size: 0.9rem !important;
  background: #fff !important;
  border-right: 0px !important;
  border-color: #e4e4e4 !important;
}

.date-range-picker-style {
  z-index: 1 !important;
  position: absolute !important;
  background-color: white;
}

.transaction-search-field-title {
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 0.8125rem;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

.search-trans-button {
  margin-left: 15px;
  margin-bottom: 0.5rem;
}

.dropdown-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}

/**transaction table**/
.transaction-date-col {
  width: 5% !important;
}

.transaction-user-col {
  width: 18% !important;
}

.transaction-ic-col {
  width: 7% !important;
}

.transaction-phone-col {
  width: 5% !important;
}

.transaction-membership-col {
  width: 10% !important;
}

.transaction-desc-col {
  width: 30% !important;
}

.transaction-point-col {
  width: 15% !important;
}

.transaction-action-col {
  width: 5% !important;
}

.transaction-user-td-col {
  white-space: break-spaces !important;
}

.transaction-desc-td-col {
  white-space: break-spaces !important;
}

.transaction-body-row {
  color: #24293a !important;
}

.transaction-pts-add-container {
  background: #ebf7e7 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  color: #16951c;
  font-size: 0.9375rem;
  text-align: center;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  white-space: break-spaces;
}

.transaction-pts-minus-container {
  background: #f7ebe7 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  color: #d82929;
  font-size: 0.9375rem;
  text-align: center;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  white-space: break-spaces;
}

.transaction-action-delete {
  text-align: center !important;
  cursor: pointer;
}

/************************************************************************************
transaction end
************************************************************************************/

/************************************************************************************
credit modal start
************************************************************************************/
.credit-modal-header-text {
  justify-content: center !important;
}

.credit-modal-header-text h5 {
  font-size: 1.625rem;
  font-weight: bold;
  color: #24293a;
}

.close-button {
  position: absolute;
  right: 0;
  margin-top: 0rem !important;
  margin-left: 0rem !important;
  margin-bottom: 0rem !important;
  margin-right: 1rem;
  cursor: pointer;
}

.credit-row-container {
  margin-bottom: 2rem;
}

.question-col-container {
  font-weight: bold;
  font-size: 0.9375rem;
  color: #24293a;
}

.credit-modal-customer-select-box {
  width: 100%;
}

.credit-modal-radio-row-container {
  margin-left: 0.9375rem;
}

.credit-modal-radio-container {
  display: flex;
  flex-direction: column;
}

.radio-title {
  font-weight: bold;
  opacity: 1;
  color: #24293a;
  font-size: 1rem;
}

.point-action-desc {
  font-weight: 600;
  opacity: 0.5;
  color: #24293a;
  font-size: 0.8125rem;
}

.point-container {
  display: flex;
  flex-direction: row;
}

.point-action-icon {
  cursor: pointer;
}

.point-input-control {
  width: 50% !important;
}

.point-input-control .form-control {
  border: 1px solid #e4e4e4 !important;
  padding-left: 1rem !important;
  text-align: center;
}

.point-input-control .form-control::placeholder {
  color: #e6e7e9;
  opacity: 1;
  font-weight: 700;
  font-style: italic;
}

.point-input-control .form-control:focus {
  background: white;
  color: #393939;
}

.point-form-prepend-text {
  font-weight: 700 !important;
  color: #24293a !important;
  font-size: 0.9rem !important;
  background: #f4f4f4 !important;
  cursor: pointer;
}

.point-input-control-append {
  background: #f4f4f4 !important;
}

.point-input-control-append .form-control {
  padding-left: 1rem !important;
  cursor: pointer;
}

.point-input-control-append .form-control::placeholder {
  color: #e6e7e9;
  opacity: 1;
  font-weight: 700;
  font-style: italic;
}

.point-input-control-append .form-control:focus {
  background: white;
  color: #393939;
}

.point-form-append-text {
  border-left: 0px !important;
  border-color: #cad1d7 !important;
}

.point-form-append-text-error {
  border-left: 0px !important;
  border-color: red !important;
}

.credit-modal-text-area {
  max-height: 11.125rem;
  min-height: 6.25rem;
  color: #24293a !important;
}

.add-point-footer-button {
  background-color: #af8e56 !important;
  color: #fff !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
/************************************************************************************
credit modal end
************************************************************************************/

/************************************************************************************
customer start
************************************************************************************/
.customer-history-container {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.customer-history {
  border-right: 1px solid #fbfbfb;
  color: #000;
  font-weight: bold;
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.3125rem;
  padding-right: 1rem;
  flex: 1;
}

.customer-count {
  color: #5c6586;
  font-weight: 600;
  padding-right: 1rem;
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1rem;
  flex: 1;
  padding-left: 1rem;
}

.customer-export-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  flex-wrap: nowrap;
}

.customer-search-input-control {
  width: 70% !important;
}

.customer-search-input-control .form-control {
  border: 1px solid #e4e4e4 !important;
  padding-left: 1rem !important;
  font-weight: bold !important;
  color: #24293a !important;
  border-right: 0px !important;
  background: #f3f4f6 !important;
}

.customer-search-input-control .form-control::placeholder {
  color: #c0bfbf;
  opacity: 1;
  font-weight: 600;
  font-style: italic;
}

.customer-search-input-control .form-control:focus {
  color: #393939;
}

.customer-search-append-text {
  border-left: none !important;
  border-color: #e4e4e4 !important;
  background: #f3f4f6 !important;
}

/*customer table*/
.table-user-avatar {
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.customer-date-col {
  width: 15% !important;
}

.customer-user-col {
  width: 37% !important;
}

.customer-ic-col {
  width: 8% !important;
}

.customer-phone-col {
  width: 10% !important;
}

.customer-gender-col {
  width: 5% !important;
}

.customer-point-col {
  max-width: 10% !important;
}

.customer-date-col {
  width: 15% !important;
}

.customer-action-col {
  width: 10% !important;
}

.customer-user-td-col {
  white-space: break-spaces !important;
}

.customer-desc-td-col {
  white-space: break-spaces !important;
}

.customer-point-td-col {
  opacity: 1;
  font-size: 0.9375rem;
  text-align: center;
  white-space: break-spaces;
  word-wrap: break-word;
  width: 10%;
}

.customer-body-row {
  color: #24293a !important;
  cursor: pointer;
}
/* customer table*/

/* customer-info */
.customer-info-container {
  background: white;
  margin: 1rem;
  border-radius: 5px;
  display: flex;
  padding-top: 1.2rem;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  flex-direction: column;
}

.point-history-title {
  width: 12%;
  font-weight: bold;
  letter-spacing: 0px;
  color: #24293a;
  opacity: 1;
  font: 0.9375rem;
  margin-right: 1.5rem;
  cursor: pointer;
}

.point-history-title-active {
  /* border-bottom: 2px solid var(--point-history-bottom-underline); */
  width: 12%;
  font-weight: bold;
  letter-spacing: 0px;
  color: #24293a;
  opacity: 1;
  font: 0.9375rem;
  margin-right: 1.5rem;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: var(--point-history-bottom-underline);
  text-decoration-style: solid;
}

.customer-info-external-layout {
  display: flex;
  flex-direction: row;
  flex: 1;
  /* align-items: center; */
}

.cus-name-outer-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.cus-name-outer-container .avatar {
  height: 5rem;
  width: 5rem;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}

.cus-name-container {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  /* margin-left: auto;
  margin-right: auto; */
}

.c-name {
  font-size: 1.375rem;
  font-weight: bold;
  opacity: 1;
  color: #24293a;
  text-align: center;
  font-size: 1.2rem;
}

.c-point {
  font-size: 1.0625rem;
  font-weight: bold;
  opacity: 1;
  color: #24293a;
  text-align: center;
  font-size: 0.9rem;
}

.c-cash {
  font-size: 1.0625rem;
  font-weight: bold;
  opacity: 1;
  color: var(--button-background-color);
  text-align: center;
  font-size: 0.9rem;
}

.c-key {
  font-size: 0.9375rem;
  font-weight: bold;
  opacity: 1;
  color: #24293a;
}

.c-value {
  font-size: 0.9375rem;
  font-weight: bold;
  opacity: 0.8;
  color: #24293a;
}

.cus-detail-info-external-contaianer {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  flex: 1;
  border-left: 2px solid #fbfbfb;
}

/* customer-info */
.delete-member-modal-negative-button {
  width: 20%;
  background-color: #fff !important;
  color: #af8e56 !important;
  border: solid 1px #af8e56 !important;
}

.delete-member-modal-positive-button {
  width: 20%;
  background-color: #af8e56 !important;
  color: #fff !important;
}

.delete-member-modal-footer-container {
  display: flex;
  flex-direction: row;
  flex: 1;
}
/************************************************************************************
customer end
************************************************************************************/

/************************************************************************************
customer detail start
************************************************************************************/
.edit-icon {
  cursor: pointer;
}

.more-dropdown-container {
  font-weight: bold;
  font-size: 0.9375rem;
  color: #000;
  letter-spacing: 0px;
  opacity: 1;
  cursor: pointer;
}

.more-dropdown-icon {
  margin-right: 0.5rem;
}

.customer-transaction-container {
  background: white;
  margin: 1rem;
  border-radius: 5px;
  display: flex;
  padding-top: 1.2rem;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  padding-bottom: 1.2rem;
  flex-direction: column;
}

.calendar-cus-tran-input-control {
  width: 30% !important;
}

.calendar-cus-tran-input-control .form-control {
  border: 1px solid #e4e4e4 !important;
  padding-left: 1rem !important;
  border-left: 0px !important;
  font-weight: bold !important;
  color: #24293a !important;
}

.calendar-cus-tran-input-control .form-control:read-only {
  background: white !important;
}

.calendar-cus-tran-input-control .form-control::placeholder {
  color: #e6e7e9;
  opacity: 1;
  font-weight: 700;
  font-style: italic;
}

.calendar-cus-tran-input-control .form-control:focus {
  background: white;
  color: #393939;
}

.transaction-component-outer-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  /* justify-content: space-between; */
  padding-right: 0.3125rem;
}

.transaction-component-info-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  margin-right: 2rem;
  flex: 0.8;
}

.trans-type {
  font-weight: bold;
  font-size: 0.9375rem;
  color: #24293a;
}

.trans-date {
  font-weight: bold;
  font-size: 0.8125rem;
  color: #24293a;
}

.trans-desc {
  font-weight: 600;
  color: #24293a;
  font-size: 0.75rem;
  opacity: 0.5;
  letter-spacing: -0.0075rem;
  text-align: justify;
}

.trans-pts-add-container {
  background: #ebf7e7 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  color: #16951c;
  font-size: 0.9375rem;
  text-align: center;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  white-space: break-spaces;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
  align-self: center;
  flex: 0.2;
  display: inline-block;
  justify-content: center;
}

.trans-pts-minus-container {
  background: #f7ebe7 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  color: #d82929;
  font-size: 0.9375rem;
  text-align: center;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  white-space: break-spaces;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
  align-self: center;
  flex: 0.2;
  display: inline-block;
  justify-content: center;
}

.divider-hr-line {
  border-top: 1px solid #f6f6f6;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.promote-modal-radio-container {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}
/************************************************************************************
customer detail end
************************************************************************************/

/************************************************************************************
new customer start
************************************************************************************/
.new-customer-header-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  background: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.new-customer-title {
  color: #000;
  font-weight: bold;
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.3125rem;
  padding-right: 1rem;
}

.new-customer-breadcrumb {
  font-weight: 600;
  padding-right: 1rem;
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
}

.breadcrumb-first {
  text-decoration: underline;
  padding-right: 0.5rem;
  color: var(--breadcrumb-first-text);
  cursor: pointer;
}

.breadcrumb-second {
  padding-left: 0.5rem;
  color: var(--breadcrumb-second-text);
}

.customer-profile-container {
  background: white;
  margin: 1rem;
  border-radius: 5px;
  display: flex;
  padding: 1rem;
  flex-direction: column;
}
/************************************************************************************
new customer end
************************************************************************************/

/************************************************************************************
customer profile start
************************************************************************************/
.customer-profile-external-layout {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.customer-profile-layout {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 1rem;
}

.customer-profile-avatar {
  color: #fff;
  background-color: #adb5bd;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 6.5rem;
  width: 6.5rem;
  border-radius: 50% !important;
  margin-right: 1rem;
}

.customer-profile-info-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* margin-left: 1rem; */
}

.customer-profile-form-title {
  font-weight: 700;
  font-size: 0.9375rem;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
}

.customer-profile-input-container {
  display: flex;
  flex-direction: column;
}

.customer-profile-input-control {
}

.customer-profile-input-control .form-control {
  border: 1px solid #cad1d7;
  padding-left: 1rem !important;
}

.customer-profile-input-control .form-control::placeholder {
  color: #e6e7e9;
  opacity: 1;
  font-weight: 700;
  font-style: italic;
}

.customer-profile-input-control .form-control:focus {
  background: white;
  color: #393939;
  border: 1px solid #cad1d7;
}

.customer-profile-form-prepend-text {
  font-weight: 700 !important;
  color: #24293a !important;
  font-size: 0.9rem !important;
}

.gender-select-box {
  width: 100%;
}

.customer-profile-buttons-container {
  display: flex;
  flex-direction: row;
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
  justify-content: flex-end;
}

.customer-profile-field-desc {
  color: #24293a;
  opacity: 0.88;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.registration-checkbox-container {
  display: flex;
  flex-direction: row;
  padding-left: 0.5rem;
}

.registration-checkbox-option-container {
  flex: 1;
}

.registration-checkbox {
  position: inherit !important;
  margin-left: 0.5rem;
}
/************************************************************************************
customer profile end
************************************************************************************/

/************************************************************************************
not found start
************************************************************************************/
.not-found-container {
  background: white;
  border-radius: 5px;
  display: flex;
  padding: 1rem;
  height: 100vh;
  flex-direction: column;
  flex: 1;
}

.not-found-external-layout {
  flex: 1;
  height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.not-found-title {
  font-size: 1.5rem;
  font-size: 3.6vw;
  font-weight: bold;
  color: #24293a;
  margin-bottom: 2vw;
}

.not-found-subtitle {
  font-size: 1.25rem;
  font-size: 3vw;
  font-weight: 600;
  color: #24293a;
  opacity: 0.6;
  margin-bottom: 2vw;
}

.not-found-desc {
  font-size: 0.875rem;
  font-size: 2.1vw;
  font-weight: 300;
  color: #24293a;
  opacity: 0.6;
  margin-bottom: 2vw;
}

.not-found-container {
  background-color: #fff;
}

.homepage-button {
  width: fit-content;
}
/************************************************************************************
not found end
************************************************************************************/

/************************************************************************************
company component start
************************************************************************************/
.register-title {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 0.8;
}
/************************************************************************************
company component end
************************************************************************************/

/************************************************************************************
reward listing start
************************************************************************************/
.rewards-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px 10px;
  margin-top: 15px;
  background-color: transparent;
}

.card-outer-container {
  flex: 1;
  flex-direction: column;
  display: flex;
  border-color: #bdbebf !important;
}

.card-body-container {
  flex-direction: column;
  display: flex;
  font-size: 0.8rem;
  flex: 1;
}

.card-title-bold {
  font-weight: bold;
  margin-right: 3rem;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.right-icon-container {
  flex-direction: row;
}

.edit-icon {
  margin-right: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #f5f5f5;
}

.pointer-cursor {
  cursor: pointer;
}

.tier-modal-footer-container {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.tier-modal-positive-button {
  width: 20%;
  background-color: #af8e56 !important;
  color: #fff !important;
}

.tier-modal-negative-button {
  width: 20%;
  background-color: #fff !important;
  color: #af8e56 !important;
  border: solid 1px #af8e56 !important;
}
/************************************************************************************
reward listing end
************************************************************************************/

/************************************************************
password indicator start
************************************************************/
.indicator-outer-container {
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.indicator-container {
  background: #d6d6d6;
  border-radius: 0.3125rem;
  opacity: 1;
  padding: 0.5rem;
  display: flex;
  margin-right: 1rem;
}

.indicator-invalid-text {
  font-weight: bold;
  letter-spacing: 0px;
  color: #24293a;
  opacity: 0.3;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.indicator-valid-text {
  font-weight: bold;
  letter-spacing: 0px;
  color: #16951c;
  opacity: 1;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.indicate-tick-image {
  display: block;
  width: auto;
  height: auto;
  object-fit: contain;
}

/************************************************************
password indicator end
************************************************************/

.address-block-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
}

.login-form-prepend-text {
  font-weight: 700 !important;
  color: #24293a !important;
  font-size: 0.9rem !important;
  border-right: 2px solid #cad1d7 !important;
}

.download-modal-positive-button {
  background-color: #af8e56 !important;
  color: #fff !important;
}

.download-modal-negative-button {
  background-color: #fff !important;
  color: #af8e56 !important;
  border: solid 1px #af8e56 !important;
}

.download-modal-header {
  background-color: #af8e56;
  color: #fff !important;
}

.download-manager {
  border-right: 1px solid #fbfbfb;
  color: #000;
  font-weight: bold;
  padding-right: 1rem;
  align-self: left;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.3125rem;
  margin-left: 15px;
}
